import homeHero from "./home_hero.webp";
import homeCards from "./home_cards.webp";
import scene from "./scene.webp";
import mockup from "./mockup.webp";

export default {
  homeHero,
  homeCards,
  scene,
  mockup,
};
