import React from 'react';

const Button = () => {
  return (
    <div >
      <div className="flex flex-col justify-start ml-4">
        <a href="https://apps.apple.com/us/app/klein-vaarbewijs-1/id6443716435?itsct=apps_box_badge&amp;itscg=30200" style={{display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px"}}>
          <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1665273600" alt="Download on the App Store" style={{borderRadius: "13px", width: "250px", height: "83px"}}/>

          </a>
      </div>
    </div>
  )
}

export default Button