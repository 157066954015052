import { Download, Features, SectionWrapper } from './components';
import assets from './assets';
import styles from './styles/Global';

const App = () => {
  return (
    
    <>
      <SectionWrapper 
        title="Bereid je voor op het Klein Vaarbewijs 1 examen met onze app."
        description="Met onze mobiele app kun je gemakkelijk en overal jouw theoretische kennis oefenen voor het Klein Vaarbewijs 1, zodat jij goed voorbereid het examen in gaat!"
        showBtn
        mockupImg={assets.homeHero}
        banner="banner"
        altText="twee Iphone mockups van app - homescreen en vragen over verkeersborden"
      />
      <SectionWrapper 
        title="Een plek voor jouw oefenvragen voor het Klein Vaarbewijs 1 examen."
        description="Wist je dat je met onze app op een makkelijke manier theoretische kennis kunt oefenen voor jouw Klein Vaarbewijs 1? Zo vergroot jij je kennis op een leuke manier en behaal jij straks moeiteloos je vaarbewijs!"
        mockupImg={assets.homeCards}
        reverse
        altText="Iphone mockup van app - theoretische oefenvraag"
      />
      <SectionWrapper 
        title="Realistische oefenvragen voor jouw Klein Vaarbewijs 1 examen."
        description="Test je kennis van signaleringsborden op het water met onze mobiele app en vaar straks veilig en met vertrouwen!"
        mockupImg={assets.mockup}
        banner="banner02"
        altText="Drie Iphone mockups van app - drie verschillende schermen met vragen over verkeersborden"
      />
      <Download
      showBtn/>
      <div className="px-4 py-2 justify-center items-center bg-primary flex-col text-center banner04">
        <p className={`${styles.pText} ${styles.whiteText}`}> {" "}
        <span className="bold"></span>
        </p>
      </div>
    </>

  );
}

export default App;
