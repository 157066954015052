import React from 'react';

import styles from '../styles/Global';
import assets from '../assets';
import Button from './Button';

const Download = ({showBtn}) => {
  return (
    <div className={`${styles.section} ${styles.bgWhite}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.blackText}`}>Download de app in de App Store</h1>

          {/* <p className={`${styles.pText} ${styles.blackText}`}>Get the full source code on GitHub</p> */}
        </div>
        <div className={styles.flexCenter}>
          <img 
            src={assets.scene}
            alt="Vijf Iphone mockups met van mobile app. Twee met afbeeldingen over vragen, een homescreen en twee of theoretische vragen."
            className={styles.fullImg}
          />
        </div>
        {showBtn && (
            <Button 
              />
          )}
      </div>
    </div>
  )
}

export default Download